:root {
    /* INPUT */
    --search-input-stroke: #C0C0C0;
    --search-input-stroke-active: #28113C;
    --search-input-color: #28113C;
    --search-slot-rolling-effect-color: #a9a9a9;
    --search-input-icon-x: #818181;
    --search-title-bottom-margin: 18px;
    --margin-input-icon-magnify: 12px;

     /* ICON */
     --search-icon-arrow: url('../../search/image/boabet/arrow.svg');
     --search-icon-magnify: url('../../search/image/boabet/magnify.svg');
     --search-icon-close: url('../../search/image/boabet/close.svg');
     --search-icon-clear: url('../../search/image/boabet/close.svg');

    /* BACKGROND */
    --search-bg-placeholder: #E7E7E7;
    --search-background-radius: 8px;
    --search-background-mobile-radius: 8px;
    --search-overlay-backdrop-color: #000;

    /* GAME GRIDS */
    --search-results-grid: repeat(2, 1fr);
    --search-results-sm-grid: repeat(3, 1fr);
    --search-results-md-grid: repeat(4, 1fr);
    --search-results-lg-grid: repeat(5, 1fr);
    --search-carousel-width: 164px;
    --search-game-tile-height: 220px;

    /* FILTER DROPDOWN */
    --search-filter-bg-color: #1a3c4e;
    --search-filter-stroke: #1a3c4e;
    --search-filter-text: #FFF;
    --search-filter-icon: #FFF;
    --search-input-border-width: 1px;

    /* PADDINGS */
    --search-result-padding-top: 0;
}

.search-component {
    justify-content: left;
}

.search-title,
.provider-dropdown-top-text,
.load-more-button {
    text-transform: uppercase;
}

.provider-dropdown-top-text {
    font-weight: bold;
}